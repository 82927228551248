.dept-name {
	text-overflow: ellipsis;
	display: -webkit-box;
	max-width: 400px;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
}

.notification-body {
	text-overflow: ellipsis;
	display: -webkit-box;
	max-width: auto;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
}

.indexedStep {
	color: white;
	width: 20px;
	height: 20px;
	font-size: 12px;
	background-color: rgba(211, 211, 211, 0.8);
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.indexedStep.accomplished {
	background-color: rgba(0, 116, 217, 1);
}

.application-progress-container {
	text-align: center;
	min-height: 60vh;
}

/* Step bar CSS */

.stepper-wrapper {
	font-family: Arial;
	margin-top: 50px;
	display: flex;
	justify-content: space-between;
	margin-bottom: 20px;
}

.stepper-item {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	flex: 1;

	@media (max-width: 768px) {
		font-size: 12px;
	}
}

.stepper-item::before {
	position: absolute;
	content: "";
	border-bottom: 2px solid #ccc;
	width: 100%;
	top: 20px;
	left: -50%;
	z-index: 2;
}

.stepper-item::after {
	position: absolute;
	content: "";
	border-bottom: 2px solid #ccc;
	width: 100%;
	top: 20px;
	left: 50%;
	z-index: 2;
}

.stepper-item .step-counter {
	position: relative;
	z-index: 5;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	background: #ccc;
	margin-bottom: 6px;
}

.stepper-item.active {
	font-weight: bold;
}

.stepper-item.completed .step-counter {
	background-color: #4bb543;
}

.stepper-item.completed::after {
	position: absolute;
	content: "";
	border-bottom: 2px solid #4bb543;
	width: 100%;
	top: 20px;
	left: 50%;
	z-index: 3;
}

.stepper-item:first-child::before {
	content: none;
}

.stepper-item:last-child::after {
	content: none;
}

.ban-icon {
	color: #ba0618;
	transform: rotate(45deg);
}

.profile_icon {
	height: 70px;
	width: 70px;
	max-height: 70px;
	max-width: 70px;
	border-radius: 50%;
	margin-left: 20%;
	margin-bottom: 10px;
}

/* start user proifle img */
.profile-container {
	position: relative;
}

.image {
	opacity: 1;
	display: block;
	width: 100%;
	height: auto;
	transition: 0.5s ease;
	backface-visibility: hidden;
}

.middle {
	transition: 0.5s ease;
	opacity: 0;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	text-align: center;
}

.profile-container:hover {
	background-color: #8082815b;
}

.profile-container:hover .image {
	opacity: 0.3;
}

.profile-container:hover .middle {
	opacity: 1;
}

.text {
	color: #ab0123;
}

/* end user profiel img */

.upload-spinner {
	width: 3rem !important;
	height: 3rem !important;
	margin-left: 20% !important;
}

@media (max-width: 991px) {
	.masthead-mob-space {
		padding-top: 0px !important;
	}
}

@media screen and (575px <width < 800px) {
	.section-masthead>.container {
		margin: 0 0 0 0;
		max-width: 100vw !important;
	}
}

@media screen and (min-width: 800px) and (max-width: 1440px) {
	.dashboard-banner-image {
		object-fit: cover;
		min-height: 250px;
		object-position: center;
		border-radius: 20px;
	}
}

.applied-jobs-list>div {
	min-width: 200px;
}

@media screen and (min-width: 750px) and (max-width: 900px) {
	.applied-jobs-list>div {
		min-width: 140px;
	}
}

@media screen and (min-width: 650px) and (max-width: 750px) {
	.applied-jobs-list>div {
		min-width: 110px;
	}
}

@media screen and (max-width: 650px) {
	.applied-jobs-list {
		display: block !important;
		width: 100%;
	}

	.applied-jobs-list>div {
		min-width: 100%;
		margin-bottom: 10px;
		padding: 0;
	}
}

.modal-content {
	max-height: 690px;
}