.page_heading {
	text-align: center;
}
.page_para {
	text-align: center;
}

/* @media (min-width: 1143px) {
	.footer-container {
		min-height: 50px;
	}
}

@media (min-width: 1143px) {
	.main-container {
		min-height: 100%;
		margin-bottom: -50px;
		padding-bottom: 50px;
		box-sizing: border-box;
	}
} */

