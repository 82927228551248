.paragraph1 {
	white-space: pre-wrap !important;
	max-width: fit-content;
}
.bookmark .copy {
	max-width: 11%;
	margin-bottom: "10px";
}
.button-row {
	margin: 0 20% 0 20%;
}
.sim-jobs {
	/* padding: 10% 10% 10% 10% !important; */
	/* z-index: 1; */
}
.horizontal-tab .tab-menu .tabs-left {
	display: flex !important;
}
@media screen and (766px < width<1000px) {
	.sim-jobs {
		margin-top: 3px;
		padding: 10% 10% 10% 10% !important;
	}
}
@media screen and (min-width: 992px) {
}
@media (max-width: 992px) {
	.sticky-top-mobile {
		z-index: 0;
	}
}
.font-family
{
 font-family:JioTypeW04-Light !important;
}
.custom
{
	margin-left: 0px !important;
}
.box {
    inline-size: 540px!important;
    overflow-wrap: break-word;
}
.text
{    font-family: JioTypeW04-Bold!important;
    font-weight: 400;
}
.job-description .right-column {
	width: 90%;
	float: left;
  }

