.homepage {
	margin-top: 35.5px !important;
}

.homepage .section-common-space {
	overflow-y: auto;
}

.Welcome-text {
	margin-bottom: 10px;
	margin-left: 5px;
}

.event_goto_btn::after {
	color: #1b1e21;
}

.overview {
	background-color: #fff !important;
	outline: 0 !important;
	border: 0 !important;
	box-shadow: 0 0 14px rgba(33, 71, 150, 0.16) !important;
	padding: 0 !important;
	height: 46px !important;
	font-size: 14px !important;
	font-family: JioTypeW04-Medium !important;
	border-radius: 10px !important;
	width: 100% !important;

	box-sizing: border-box !important;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.overview input {
	width: 100%;
	padding: 3.5%;
	border: none;
}

.main_page_srch_icon {
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
}

.slick-txt {
	min-height: 18vh;
	max-width: 70%;
	text-align: justify;
}

.leaders-img {
	background-color: #e7eaf4 !important;
}

.leader-title {
	min-height: 6vh;
	white-space: nowrap;
}

.leader-honors {
	margin-top: 3px;
	min-height: 6vh;
}

.aoi-slick {
	min-height: 40vh;
}

.leader-card-body {
	min-height: 22vh;
}

.deps-body {
	min-height: 23vh;
	margin-bottom: 0.5rem;
}

.section-common-space {
	padding-bottom: 1rem;
	padding-top: 1rem;
}

.facilities-slider img {
	max-height: 70px;
	min-height: 65px;
}
@media (min-width: 992px) {
	.slider-circle-panel {
		padding-top: 3rem;
		padding-bottom: 0rem;
	}
}
