/* .section-common-space.window-height {
	height: auto;
} */
.cmp-slick {
	height: 100vh;
}
.why-join-us  .section-common-space {
    padding-bottom: 1rem;
    padding-top: 1rem;
}
@media screen and (min-width: 992px) {
	.container > .sec-4 {
		margin-bottom: 7vh !important;
		margin-top: 6vh !important;
	}
}

@media screen and (max-width: 992px) {
	.section-container {
		margin-top: 2rem !important;
		margin-bottom: 2rem !important;
	}
	.section-container-3 {
		margin-top: -2rem !important;
		margin-bottom: -2rem !important;
	}
	.slick-section-container {
		margin-top: 2rem !important;
	}
}
