.tab-content > .tab-pane section.mt-4 {
    margin-top: 1.1rem !important;
}
@media (min-width: 992px) {
	.horizontal-tab .tab-menu {
		min-height: auto;
	}
}

@media ( max-width: 998px) {
	.career-application-steps .tab-menu {
		max-width: 100vw;
		width: -webkit-fill-available !important;
	}
    .tab-content > .tab-pane section.mt-4 {
    margin-top: 1.25rem !important;
}
}

.input-file-frame .form-group.custom-file .custom-file-label{
	display: inline-block;
	white-space: nowrap;
	overflow: hidden !important;
	text-overflow: ellipsis;
	padding-right: 95px;
	}