.nav-active {
	color: #fff;
	border-bottom: 2px solid #fff !important;
}
/* ..section-common-space {
	padding-top: ;
} */
.social-media-icon {
	margin-right: 13px;
}
.last-child {
	margin-right: 0px !important;
}
.footer_container {
	position: relative !important;
	left: 0 !important;
	bottom: 0 !important;
	width: 100% !important;
	overflow: hidden !important;
}
.footer_item_container {
	display: flex;
	align-items: center;
	justify-content: center;
}

.user_profile {
	padding-right: 50px;
}
.profile_icon {
	height: 70px;
	width: 70px;
	max-height: 70px;
	max-width: 70px;
	border-radius: 50%;
}
::-webkit-scrollbar {
	/*width: 0px;*/
}
.badge-title {
	text-align: "center";
	font-size: 20;
}
.badge-body {
	text-align: "center";
	font-size: 20;
	color: black;
}
.user_img {
	cursor: pointer !important;
}
.user_name {
	cursor: default !important;
}

.dropdown-menu.show {
	display: flex;
	flex-wrap: wrap;
}

@media (max-width: 991px) {
	.dropdown-menu.show {
		position: relative !important;
		display: block;
		width: 100%;
		border: 0;
		inset: 0px auto auto 0px;
		padding-left: 1rem;
		transform: translate3d(0px, 0px, 0px) !important;
	}
	.footer_container {
		position: static !important;
	}
}
.header-container {
	min-height: 106px;
}
.header-top-space {
	height: 66px;
}
.header-logo,
.sub-header {
	margin-top: 10px;
}
@media screen and (0 < width < 992px) {
	.site-header-wrap1 {
		margin-top: 1rem !important;
	}
}

@media (max-width: 1030px) {
	.header-top-space {
		height: 51px;
		margin-top: 0;
	}
}

@media (min-width: 1143px) {
	.footer-container {
		min-height: 50px;
	}
}

@media (min-width: 1143px) {
	.main-container {
		min-height: 100%;
		margin-bottom: -50px;
		padding-bottom: 50px;
		box-sizing: border-box;
	}
}

@media (max-width: 991px) {
	.site-header-wrap1 ul li.dropdown {
		background: none;
	}
}

@media screen and (0 < width < 768px) {
	.header-container {
		min-height: 86px !important;
	}
}

@media (width <= 576px) {
	.phone-container {
		margin-left: -15px !important;
	}
}
.group-id-hide-2{
	display: none !important;
}