.verification-label {
	margin-left: 6rem;
}
.sync-btn {
	border-radius: 1.3rem;
}
.sync-icon-btn {
	padding-top: 0.3rem;
}
.captcha-input {
	padding-top: 1rem;
}
.captcha-img {
	border: 5px 5px;
	border-color: rgba(151, 176, 201);
	/*margin-top: 10px;*/
}
