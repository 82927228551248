a {
	cursor: pointer;
}

/* @media (0 <width< 992px) {
	.section-common-space {
		padding-bottom: 1rem;
		padding-top: 5rem;
	}
} */
.main-container {
	margin-top: 55px;
}

.breadcrumb {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	padding: 0.75rem 1rem;
	margin-bottom: 1rem;
	list-style: none;
	background-color: hsla(220, 18%, 97%, 0);
	border-radius: 0.25rem;
}

.main-container .breadcrumb .breadcrumb-item {
	text-transform: none;
}

@media (992px < width < 1191px) {
	.main-container {
		min-height: 100%;
		margin-bottom: -50px;
		padding-bottom: 50px;
		box-sizing: border-box;
	}

	.footer_container .ji-logo-frame {
		min-width: 9.125rem;
	}

	.footer_container .ml-lg-2,
	.mx-lg-2 {
		margin-left: 0.1rem !important;
	}
}

@media (max-width: 991px) {
	.signup-row {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	.signup-img-wrap {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.signup-img {
		width: 100% !important;
		height: 260px;
		margin-top: 3rem;
	}
}

@media (max-width: 450px) {
	.signup-img {
		width: 100% !important;
		height: 250px;
		margin-top: 3rem;
	}
}

@media (width <= 576px) {
	.signup-row {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		margin-top: 5vh;
	}
}
:focus:not(:focus-visible) {
	outline: 0;
	box-shadow: 0 0 0 0.1rem rgba(0, 123, 255, 0);
	border: dotted 1px transparent;
}
:focus {
	box-shadow:none;
	border: none
  }
  .dropdownX:hover > .dropdown-menu {
    display: block;
  }
  .dropdown:hover>.dropdown-menu {
	display: block;
  }
 
  .desktop-nav-container .nav .dropdown-menu{
	  transform: translate3d(0px, 32px, 0px) !important;

  }
  .desktop-nav-container .nav .dropdown-menuX{
	transform: translate3d(0px, 0px, 0px) !important;

  }

  .ji-logo-frame {
	min-width: 11.5rem !important;
  }
  .otp_fields{
	width: 2.7rem !important;
  }

  .form-group.input-container select.form-control{
	display: inline-block;
	white-space: nowrap;
	overflow: hidden !important;
	text-overflow: ellipsis;
	padding-right: 40px !important;
}