.paragraph {
	margin-left: 0;
	text-align: left !important;
	color: #2b2c30 !important;
	font-family: JioTypeW04-Light !important;
	font-weight: 400;
	font-size: 0.875rem;
	line-height: 1.125rem;
	white-space: pre-line !important;
	overflow: hidden;
	text-overflow: ellipsis;
	line-height: 1.5em;
	height: 3em;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	width: 100% !important;
}

.duration-passed {
	margin-top: 0.5% !important;
	color: #52555e !important;
	/* position: absolute; */
	/* left: 84% !important; */
	font-size: 12px;
	/* overflow: hidden; */
}
.row-container {
	width: 100vw;
}

.primary-color {
	width: 100% !important;
	display: flex;
}

.my-4 {
	margin-top: 0.01 !important;
}

.right-column1 {
	margin-top: -15%;
}

.right-column {
	margin-top: -15%;
}

.paragraph1 {
	margin-left: 0 !important;
	text-align: left !important;
	color: #52555e !important;
	font-family: JioTypeW04-Light !important;
	font-weight: 400;
	font-size: 0.875rem;
	line-height: 1.125rem;
	/* inline-size: 800px; */
	overflow-wrap: break-word;
	hyphens: manual;
}

.tab-menu {
	margin-top: -145%;
	margin-right: 0 !important;
}

.pkv-searchform {
	width: 27%;
	position: relative !important;
	bottom: 10px !important;
	margin-left: 0;
	/* justify-content: center; */
	text-align: center;
}

.job-description {
	position: relative;
	top: 0.5px;
}

.text {
	margin-left: 30%;
}

.container1 {
	display: flex;
	flex-direction: row;
	/* justify-content:space-between;  */
}

.border-bottom {
	border-bottom: 5px solid #dee2e6 !important;
}

.loader_spinner {
	margin-left: 25vw;
	margin-top: 25vh;
}

.mr-1 {
	cursor: pointer;
}

.primary-color {
	cursor: pointer;
}
.pe-none {
	pointer-events: none;
}
.h6-02 {
	margin-top: 0%;
	margin-left: 10.8%;
	cursor: pointer;
	color: black;
}

.Cursor1 {
	cursor: none;
}
@media screen and (10px < width<768px) {
	.pkv-searchform {
		width: 113% !important;
	}
	.right-column1 {
		margin-top: 0%;
	}
}

@media screen and (min-width: 992px) {
	.container > .align-items-lg-center {
		margin-bottom: 10vh !important;
		margin-top: 6vh !important;
	}
	.current_openings > .section-common-space {
		margin-top: 2.1rem;
	}
}
@media (768px < width < 992px) {
	.current_openings .section-common-space {
		margin-top: 55px !important;
	}
}

.job-filter-container {
}
@media screen and (576px < width <768px) {
	.job-filter-container {
		margin-top: 4rem !important;
	}
}

.checkbox-wrapperX input:disabled {
	pointer-events: none;
  }
  .checkbox-wrapperX input:disabled + label:before {
	border-color: #aa0023;
  }
  .checkbox-wrapperX input:disabled + label:after {
	opacity: 0;
  }
  .checkbox-wrapperX input:disabled:checked + label:after {
	opacity: 1;
	background-color: #aa0023;
  }
  .checkbox-wrapperX input:disabled:checked:hover + label:before {
	border-color: #aa0023;
  }
  .checkbox-wrapperX input:disabled:checked:hover + label:after {
	background-color: #aa0023;
  }

  .job-update{
	display: none;
  }

  @media screen and (max-width: 560px) {
	.job-categories-row > div {
		min-width: 100%;
		padding-bottom: 1rem !important;
	}
	.job-update{
		display: block;
	}
  }
