.login-btn {
    display: flex;
    justify-content: first baseline;
    margin-top: 30px;
}

.sync_icon {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
}

.captcha_input {
    margin-top: 8px;
}

.isDisabled {
  color: currentColor;
  cursor: not-allowed;
  opacity: 0.5;
  text-decoration: none;
}
.login-card-container
{
    padding-top: 5rem;
}
.login-container {
	height: clamp(min(10vw, 20rem), max(66vh, 90rem), max(60vh, 5rem));
	display: flex;
	align-items: center;
}

@media screen and (min-width: 1024px) {
	.position-bottom {
		position: absolute !important;
		bottom: 0;
	  }
}



