.DivHide {
  display: none;
}

.DivShow {
  display: block;
}

/* remove below css and add "background-none" class to body */
body {
  background-image: none;
}

/* .btn {
  outline: none;
  box-shadow: none;

} */

.font-weight-bold {
  cursor: pointer;
}

.Maindiv {
  display: flex;
  /* justify-content: center; */
}

.icon {
  cursor: pointer;
  position: relative;
  /* left:66%; */
}

.dltIconPhd {
  cursor: pointer;
  position: relative;
  left: 95%;
  align-self: flex-end;
}

.dltIcon {
  cursor: pointer;
  position: relative;
  left: 65%;
  align-self: flex-end;
}

@media (max-width: 991px) {
  .dltIcon {
    cursor: pointer;
    position: relative;
    left: 95%;
    align-self: flex-end;
  }
}

@media (max-width: 337px) {
  .dltIcon {
    cursor: pointer;
    position: relative;
    left: 88%;
    align-self: flex-end;
  }

  .dltIconPhd {
    cursor: pointer;
    position: relative;
    left: 87%;
    align-self: flex-end;
  }
}

@media screen and (min-width: 992px) {
  .dltIcon {
    cursor: pointer;
    position: relative;
    left: 62%;
    align-self: flex-end;
  }
}

.disabled {
  opacity: 0.3;
  cursor: none;
}

.button-md {
  font-weight: bold;
}

/* .completed
{
  opacity: 0.5;
} */